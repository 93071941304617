<template>
  <main class="about">
    <Breadcrumb></Breadcrumb>
    <PageHeader
      title="UGA by the Numbers"
      backgroundImage="facts/38957-008.jpg"
      backgroundColor="transparent"
      textColor="white"
      overlay="light"
      align="center"
      style="background-position: top center"
      size="lg"
    >
      <p>
        Since our founding in 1785, the University of Georgia has operated as Georgia's oldest and most comprehensive institution of higher education. The proof is in our nearly 240 years of academic and professional achievements and our continual commitment to higher education. 
      </p>
    </PageHeader>
    <section class="row g-0">
      <div class="col-md-4 border-right">
        <Figure
          prefix="Top "
          :endAmount="20"
          :startAmount="50"
          :duration="2"
          subheading="Best Public Colleges"
          citation="U.S. News &amp; World Report"
          backgroundColor="grey"
          textColor="white"
          textured
        ></Figure>
      </div>
      <div class="col-md-4 border-right">
        <Figure
          prefix="#"
          :endAmount="9"
          :startAmount="20"
          :duration="2"
          subheading="Top Public Universities in the U.S."
          citation="Niche"
          backgroundColor="white"
          textColor="black"
          textured
        ></Figure>
      </div>
      <div class="col-md-4">
        <Figure
          prefix="#"
          :endAmount="7"
          :startAmount="15"
          :duration="2"
          subheading="Study Abroad Participation"
          citation="Open Doors"
          backgroundColor="red"
          textColor="white"
          textured
        ></Figure>
      </div>
    </section>
    <section class="my-4">
      <div class="container-xl">
        <div class="row" data-aos="zoom-in">
          <div class="col-sm-6">
            <Factoid
              heading="40,118 students"
              subheading="Undergraduate: 30,166<br />Graduate: 8,304<br />Professional: 1,648"
              icon="pencil"
              color="red"
            ></Factoid>
          </div>
          <div class="col-sm-6">
            <Factoid
              heading="6,200+ New First Year Students Enrolled"
              subheading="16,000 total applicants admitted in Fall 2022"
              icon="award"
              color="light-gray"
            ></Factoid>
          </div>
          <div class="col-sm-6">
            <Factoid
              heading="10,856 Total Employees"
              subheading="3,119 faculty members"
              icon="card-list"
              color="black"
            ></Factoid>
          </div>
          <div class="col-sm-6">
            <Factoid
              heading="355,000+ Living Alumni (As of Spring 2024)"
              subheading="80+ chapters and groups in Alumni Association"
              icon="person"
              color="red"
            ></Factoid>
          </div>
        </div>
      </div>
    </section>

    <section class="py-5">
      <div class="container">
        <div class="row g-5">
          <div class="col-md-6" data-aos="fade-in">
            <QuickFact
              imageURL="academic-excellence/36944-050.jpg"
              heading="Academic Offerings"
            >
              <ul>
                <li>24 Baccalaureate degrees in more than 142 fields</li>
                <li>32 Master's degrees in 135 fields</li>
                <li>
                  Professional degrees in law, pharmacy, and veterinary medicine
                </li>
              </ul>
            </QuickFact>
          </div>
          <div class="col-md-6" data-aos="fade-in">
            <QuickFact imageURL="facts/38465-004.jpg" heading="Outcome Rates">
              <ul>
                <li>
                  94.3% retention rate for incoming students who return for
                  their sophomore year. (As of Spring 2023)
                </li>
                <li>
                  96% of 2022 graduates were employed or attending graduate
                  school within 6 months.
                </li>
                <li>
                  88% six-year completion rate. 75% four-year completion rate.
                </li>
              </ul>
            </QuickFact>
          </div>
          <div class="col-md-6" data-aos="fade-in">
            <QuickFact
              imageURL="facts/38764-337.jpg"
              heading="Honors and Awards"
            >
              <ul>
                <li>
                  28 Rhodes Scholars
                </li>
                <li>
                  "Top producer" of U.S. Fulbright Student Scholars
                </li>
                <li>
                  Hundreds of faculty members are involved in national and
                  international science and research academies.
                </li>
              </ul>
            </QuickFact>
          </div>
          <div class="col-md-6" data-aos="fade-in">
            <QuickFact imageURL="research/36005-008.jpg" heading="Research">
              <ul>
                <li>$571 million in R&amp;D expenditures (FY23)</li>
                <li>
                  Top 5 university in the U.S. for new products to market, (AUTM)
                </li>
                <li>
                  1000+ products to date have been derived from university
                  research.
                </li>
              </ul>
            </QuickFact>
          </div>
          <div class="col-md-6" data-aos="fade-in">
            <QuickFact imageURL="value/37619-001.jpg" heading="Student Life">
              <ul>
                <li>More than 800 registered student organizations</li>
                <li>More than 130 study abroad &amp; exchange programs</li>
                <li>
                  One of the largest public universities to offer experiential
                  learning to 100% of students.
                </li>
              </ul>
            </QuickFact>
          </div>
          <div class="col-md-6" data-aos="fade-in">
            <QuickFact imageURL="facts/39012-010.jpg" heading="Athletics">
              <ul>
                <li>NCAA Division I, Southeastern Conference</li>
                <li>9 men's varsity sports; 12 women's varsity sports</li>
                <li>
                  45 national championships, including 33 since 1999
                </li>
              </ul>
            </QuickFact>
          </div>
        </div>
      </div>
    </section>
    <section id="impact-facts" class="bg-geo--dark">
      <div class="container-xl">
        <div class="row" data-aos="zoom-in">
          <div class="col-sm-4">
            <Factoid
              heading="465 BUILDINGS ON 760+ ACRES"
              subheading="39,000+ total acres in 31 Georgia counties"
              icon="building"
              color="black"
            ></Factoid>
          </div>
          <div class="col-sm-4">
            <Factoid
              heading="Every Georgian is within 40 miles of a UGA facility"
              subheading=""
              icon="map"
              color="black"
            ></Factoid>
          </div>
          <div class="col-sm-4">
            <Factoid
              heading="$8.1B State Impact"
              subheading=""
              icon="graph-up-arrow"
              color="black"
            ></Factoid>
          </div>
        </div>
      </div>
    </section>
    <Section
      class="mx-auto"
      type="imgBackground"
      imgSide="center"
      imgURL="homepage/37505-009.jpg"
      heading=""
      overlay=""
      :style="{
        backgroundPosition: 'center',
      }"
    >
    </Section>
  </main>
</template>

<script>
// @ is an alias to /src
import Breadcrumb from "@/components/Breadcrumb.vue";
import PageHeader from "@/components/PageHeader.vue";
import Figure from "@/components/Figure.vue";
import Factoid from "@/components/Factoid.vue";
import Section from "@/components/Section.vue";
import QuickFact from "@/components/QuickFact.vue";

export default {
  name: "Facts",
  data() {
    return {};
  },
  components: {
    Breadcrumb,
    PageHeader,
    Figure,
    Factoid,
    Section,
    QuickFact,
  },
};
</script>

<style scoped lang="scss">
  #impact-facts{
    background-color: $bulldog;
    color: $chapelbell
  }
</style>
