<template>
  <main class="value">
    <Breadcrumb></Breadcrumb>
    <PageHeader
      title="Equipping students for the real world"
      backgroundImage="value/value-hero3.jpg"
      backgroundColor="transparent"
      textColor="white"
      overlay="dark"
      align="center"
      style="background-position: bottom"
      size="lg"
    >
      <p>
        The University of Georgia’s innovative classes, study abroad opportunities, and student-centered initiatives bring exceptional value to public higher education. UGA graduates leave well-prepared for their academic and professional lives beyond the campus.
      </p>
    </PageHeader>
    <section class="row g-0">
      <div class="col-md-4 border-right">
        <Figure
          :endAmount="7"
          :startAmount="25"
          prefix="#"
          subheading="overall in study abroad participation"
          citation="2023 Open Doors U.S. Report"
          backgroundColor="red"
          textColor="white"
          textured
        ></Figure>
      </div>
      <div class="col-md-4 border-right">
        <Figure
          :endAmount="96"
          :startAmount="50"
          suffix="%"
          subheading="of 2023 graduates were employed or attending graduate school within 6 months"
          backgroundColor="black"
          textColor="white"
          textured
        ></Figure>
      </div>
      <div class="col-md-4">
        <Figure
          prefix="#"
          :endAmount="3"
          :startAmount="25"
          subheading="Best College for Student Life in America"
          citation="2023 Niche"
          backgroundColor="grey"
          textColor="white"
          textured
        ></Figure>
      </div>
    </section>
    <section style="margin: 5rem 0">
      <div class="row container-lg g-5 mx-auto">
        <div class="col-md-6" data-aos="fade-up">
          <QuickFact
            imageURL="value/experiential-learning.jpg"
            heading="A National Leader in Experiential Learning"
          >
            <p>
              The University of Georgia is one of the nation’s largest
              universities to offer
              <b>100% of undergraduate students hands-on learning experiences</b
              >, including internships, research opportunities, and study
              abroad programs. Experiential learning enhances student success both in and
              beyond the classroom.
            </p>
            <Btn
              color="black"
              size="sm"
              outline
              href="https://uga.campuslabs.com/engage/"
              >Explore More Opportunities</Btn
            >
          </QuickFact>
        </div>
        <div class="col-md-6" data-aos="fade-up">
          <QuickFact
            imageURL="value/mentors.jpg"
            heading="A Vast Bulldog Network"
          >
            <p>
              We provide one-on-one mentorships that connect students with successful professionals in our alumni network. This personal attention gives our students an edge in pursuing their chosen fields. In 2022, the UGA Mentor Program received the CASE Gold Circle of Excellence Award for Student Engagement.
            </p>
            <Btn color="black" size="sm" outline href="https://mentor.uga.edu/"
              ><span class="visually-hidden">Learn about the</span>UGA Mentor
              Program</Btn
            >
          </QuickFact>
        </div>
      </div>
    </section>
    <!--<Section data-aos="fade" type="full" imgSide="right" imgURL="img1.png">
      <h3>Undergrad and Master’s Degree in 5 Years</h3>
      <p>
        UGA’s Double Dawgs program allows ambitious and motivated students to earn both a bachelor’s degree and a master’s degree in five years or less. Students choose from more than 100 different degree combinations across various departments and colleges, saving time and money.
      </p>
      <Btn color="black" size="lg" outline href="https://doubledawgs.uga.edu/"
        >Learn More</Btn
      >
    </Section>-->
    <Section
      type="imgBackground"
      imgSide="center"
      textAlign="center"
      imgURL="value/35864-042.jpg"
      heading="Undergrad and Master’s Degree in 5 Years"
      :style="{
        backgroundPosition: 'bottom center'
      }"
    >
        <h3 class="text-center">Undergrad and Master’s Degree in 5 Years</h3>

      <p class="text-center">
        UGA’s Double Dawgs program allows ambitious and motivated students to earn both a bachelor’s degree and a master’s degree in five years or less. Students choose from more than 100 different degree combinations across various departments and colleges, saving time and money.
      </p>
      <Btn color="white" size="md" href="https://doubledawgs.uga.edu/"
        >Learn More</Btn
      >
    </Section>
    
    <section style="margin: 5rem 0">
      <div class="row container-lg g-5 mx-auto">
        <div class="col-md-6" data-aos="fade-up">
          <QuickFact
            imageURL="value/36810-025.jpg"
            heading="Student Life at UGA"
          >
            <p>
              University of Georgia students enjoy wide-ranging campus activities with a close-knit Bulldog community. With more than 800 unique organizations, our students connect with peers and mentors who share their interests and career goals.
            </p>
            <Btn
              color="black"
              size="sm"
              outline
              href="https://uga.campuslabs.com/engage/"
              >Explore Student Organizations</Btn
            >
          </QuickFact>
        </div>
        <div class="col-md-6" data-aos="fade-up">
          <QuickFact
            imageURL="value/38759-009.jpg"
            heading="Well-Being"
          >
            <p>
              UGA offers comprehensive resources to support our students, including:
              <ul>
                <li>mental health support services</li>
                <li>a University Health Center, which includes primary care, vision, dental clinics and more</li>
                <li>Disability Resource Center</li>
              </ul>
            </p>
            <Btn
              color="black"
              size="sm"
              outline
              href="https://well-being.uga.edu/"
              >Learn More<span class="visually-hidden">about wellness services offered at UGA</span></Btn
            >
          </QuickFact>
        </div>
      </div>
    </section>
    <section>
      <Section
        type="standard"
        imgSide="right"
        imgURL="value/committed-to-diversity.jpg"
        color="bg-red bg-diagonal--dark"
        class="text-white"
      >
        <h3>Committed to Diversity</h3>
        <p>
          For the 10th year in a row, the University of Georgia was recognized for our commitment to diversity and inclusion through a national Higher Education Excellence in Diversity Award.  
        </p>
        <p>
          We continuously develop new and innovative initiatives to encourage a diverse and inclusive culture, including:
        </p>
        <ul>
          <li>
            The
            <a
              class="text-white text-decoration-underline"
              href="https://dae.uga.edu/initiatives/all-georgia-program/"
              >ALL Georgia Program</a
            >, which focuses on students from rural parts of the state.
          </li>
          <li>
            The
            <a
              class="text-white text-decoration-underline"
              href="https://diversity.uga.edu/partnerships/gaame_scholars_program/"
              >Georgia African American Male Experience</a
            >, which provides support and mentoring.
          </li>
          <li>
            The
            <a
              class="text-white text-decoration-underline"
              href="https://seercenter.uga.edu/research/current-projects/delta-project/"
              >DeLTA project</a
            >, which promotes diversity in STEM fields.
          </li>
        </ul>
        <Btn color="white" size="lg" href="https://diversity.uga.edu/" outline
          >Office of Institutional Diversity</Btn
        >
      </Section>
    </section>
  </main>
</template>

<script>
// @ is an alias to /src
import Breadcrumb from "@/components/Breadcrumb.vue";
import PageHeader from "@/components/PageHeader.vue";
import Figure from "@/components/Figure.vue";
import QuickFact from "@/components/QuickFact.vue";
import Btn from "@/components/Btn.vue";
import Section from "@/components/Section.vue";

export default {
  name: "Value",
  metaInfo: {
    title: "Top Value at UGA",
    description:
      "Our innovative courses, programs, and student-centered initiatives are preparing students for their academic and professional lives beyond the campus.",
    og: {
      image: "",
      title: "",
      description: "",
    },
  },
  data() {
    return {};
  },
  components: {
    Breadcrumb,
    PageHeader,
    Figure,
    QuickFact,
    Btn,
    Section,
  },
};
</script>

<style scoped lang="scss"></style>
